@import "../../stylesheets/variables";

.Wrapper {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.Afbeelding {
  width: 100vw;
  height: 100vh;
  z-index: -1;
  position: fixed;
  background: url("../../assets/images/background.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  transform: scale(1.1);
  filter: blur(10px) grayscale(0.3);
}
.Upload {
  div i {
    font-size: 0.75em;
  }
}
