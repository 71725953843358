@import "../../stylesheets/variables";

.Center {
  height: calc(100vh - #{$navbar_spacing});
  display: flex;
  // flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.LeenButton {
  cursor: pointer;
  $size: 300px;
  margin: 0 ($size/10);
  width: $size;
  height: $size;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
  border: 5px solid $primary;
  border-radius: 25px;
  background-color: rgba($primary, 0.03);

  &:nth-child(3) .LeenButtonIcon {
    transform: scale(0.9);
  }

  @include transition(transform 0.25s ease-in-out);
  &:hover {
    transform: scale(1.05);
    .LeenButtonIcon {
      opacity: 1;
    }
    &:nth-child(1) .LeenButtonIcon {
      transform: rotate(90deg);
    }
    &:nth-child(2) .LeenButtonIcon {
      transform: rotate(-90deg);
    }
    &:nth-child(3) .LeenButtonIcon {
      transform: scale(1);
    }
  }

  .LeenButtonIcon {
    font-size: 170px;
    color: $tertiary;
    @include transition(transform 0.25s ease-in-out, opacity 0.25s ease-in-out);
    margin-bottom: 20px;
    opacity: 0.6;
  }

  p {
    font-size: 3em;
    font-weight: 500;
    color: $secondary;
  }
}
