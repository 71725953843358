@import "../stylesheets/variables";

.loginWrapper {
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loginBackground {
  position: fixed;
  width: 100%;
  height: 100%;
  background: url("../assets/images/background.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  transform: scale(1.1);
  filter: blur(10px) grayscale(0.3);
}

.HanLogo {
  height: 150px;
  background-image: url("../assets/images/logo.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
