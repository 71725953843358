@import "../stylesheets/variables";

.HomeWrapper {
  height: calc(100vh - #{$navbar_spacing});
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.Title {
  color: $primary;
}

.Clock {
  font-size: 5em;
  color: $secondary;
}
