@import "../stylesheets/variables";

.NotFound {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: calc(100vh - #{$navbar_spacing});
  text-align: center;
}

.Caption {
  color: $primary;
}

.Title {
  color: $secondary;
}