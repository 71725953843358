@import "../../../stylesheets/variables";

.InnemenArtikel {
  * {
    border-radius: 0 !important;
  }
  $r: 5px;
  padding: 0 !important;
  display: flex;
  justify-content: center;
  align-items: stretch;
  margin-top: 0.5em;
  .InnemenArtikelButton {
    border-top-left-radius: $r !important;
    border-bottom-left-radius: $r !important;
    width: 35px;
  }
  .InnemenArtikelControl {
    width: 50px;
  }
  .InnemenArtikelInfo {
    flex-grow: 1;
    padding-left: 10px;
    background-color: rgba(100, 100, 100, 0.1);
    border: 1px solid rgba(50, 50, 50, 0.3);
    border-left: none;
    border-top-right-radius: $r !important;
    border-bottom-right-radius: $r !important;
  }
}
