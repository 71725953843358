@import "../stylesheets/variables";

.SidebarContent {
  background-color: white;
  padding-top: 65px;
  height: 100%;

  .SidebarLink {
    $linkHeight: 15px;
    padding-top: $linkHeight;
    padding-bottom: $linkHeight;
    border-bottom: 1px solid #ddd;
    font-weight: bold;
    font-size: 1.3em;
    @include transition(border-left 0.2s linear);
    color: $primary !important;
    border-left: 5px solid transparent;
  }

  .SidebarLinkActive {
    border-left: 5px solid $primary;
  }
}
