@import "../stylesheets/variables";
.ImageHeight {
  height: 50px;
}

.NavigationFont {
  font-size: 1.5em;
}

.NavbarToggle {
  font-size: 2em;
  margin: 0 !important;
  color: $primary;
}

.Navbar {
  z-index: 10;
  box-shadow: 0 3px 5px rgba($color: #777, $alpha: 0.3);
  @include box-shadow(0, 3px, 5px, rgba($color: #777, $alpha: 0.3));
}

.NavbarLink {
  margin-left: 5px;
  font-weight: bold;
  border-radius: 5px;
  color: $primary !important;
  @include transition(color 0.2s linear, background-color 0.2s linear);
}

.NavbarLinkActive {
  background-color: $primary;
  color: white !important;
}

$_tranSpeed: 0.3s;
.NavbarIconShow {
  @include transition(transform $_tranSpeed, color $_tranSpeed);
  @include rotate(0deg);
  color: $tertiary !important;
}

.NavbarIconHide {
  @include transition(transform $_tranSpeed, color $_tranSpeed);
  @include rotate(180deg);
}
