@import "../../stylesheets/variables";

.Container {
  width: 100vw;
  height: calc(100vh - 65px);
  background: linear-gradient(
    rgba($color: $primary, $alpha: 0.2) 0%,
    rgba($color: $secondary, $alpha: 0.3) 100%
  );
}
