@import "../stylesheets/variables";

.FloatingActionButton {
  position: absolute;
  cursor: pointer;
  z-index: 100;
  svg {
    border-radius: 50%;
    background-color: #e9e9e9;
  }
  @include transition(transform 0.2s ease-in-out);
  &:hover {
    transform: scale(1.1);
  }
}

.ColorSquare {
  display: inline-block;
}
